body {
  background-color: lightgray;
  a{
    color: #000;
  }

  .white{
    color:#fff;
  }
  .mt30{
    margin-top: 30px;
  }
}
$primary: darken(#428bca, 20%);
@import "~bootstrap/scss/bootstrap";

#page .header{
  max-width:1240px;
  margin-top: 26px;
  a{
    color: #fff;
  }
  .search{
    .search-text{
      height: 48px;
      border: 1px solid #398be4;
    }
    .search-button{
      background: #398be4;
      font-size: 18px;
      padding: 0 20px;
      color: #fff;
      border: 1px solid #398be4;
    }
  }

  .login{
    padding-top: 10px;
    font-size: 16px;
    color: #333333;
  }
}

.footer{
  margin-top: 40px;
  width: 100%;
  overflow: hidden;
}

.menu{
  background-color: #398be4;
  margin-top: 35px;
  a{
    color: #fff;
  }
  font-size:18px;
  height: 50px;
  line-height: 50px;
  .menu-li{
    max-width:1240px;
    .col{
      text-align: center;
    }
  }
}

#page.index.auto {
  .menu {
    .auto {
      background-color: #1c6dca;

    }
  }
}

#page.index.ev {
  .menu {
    .ev {
      background-color: #1c6dca;

    }
  }
}

#page.index.image {
  .menu {
    .image {
      background-color: #1c6dca;

    }
  }
}

#page.video{
  .menu{
    .video{
      background-color: #1c6dca;
    }
  }
}

#page.news{
  .menu{
    .post{
      background-color: #1c6dca;
    }
  }
}